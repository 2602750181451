<template>
  <v-container
    id="client-forms"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row
      justify="center"
    >
      <v-col
        v-if="!loading"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[{text: 'Ваш центр', to: '/'}, {text: 'Клиенты', to: '/clients'}, {text: clientNameBreadcrumb(), to: '/client/' + subscription.client.id}, {text: 'Абонемент #' + subscription.id}]"
          large
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          v-if="!loading"
          color="success"
          icon="mdi-badge-account"
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <v-tooltip
              right
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="card-title font-weight-light"
                >
                  Абонемент #{{ subscription.id }}
                  <v-icon
                    class="cursor-pointer"
                    v-on="on"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <div
                    title="Перейти на карточку клиента"
                    class="cursor-pointer title text-uppercase client-title-link"
                    @click="singleClient(subscription.client.id)"
                  >
                    {{ subscription.client.name }}
                  </div>
                </div>
              </template>
              <span>Для каждого абонементы должна быть выбрана группа по расписанию, чтобы правильно отмечать присутствие на уроке и вести учет. Отметить присутствие можно на странице "Абонемента" или в раздел "Учебная сетка", где нужно выбрать дату и группу и отметить, кто был на уроке. Удобнее всего это сделать перед или сразу после занятия. При возврате абонемента деньги будут зачислены на счет клиента для оплаты других абонементов или услуг, если не выбрать сразу оформить возврат наличными, тогда деньги должны быть выданы клиенту.</span>
            </v-tooltip>
          </template>

          <base-material-alert
            v-if="subscription.status === -1"
            color="warning"
            dark
            icon="mdi-alert-outline"
            class="mb-0"
          >
            Абонемент закончился!
          </base-material-alert>

          <base-material-alert
            v-if="subscription.status === -2"
            color="info"
            dark
            icon="mdi-alert-outline"
            class="mb-0"
          >
            Был произведен возврат данного абонемента!
          </base-material-alert>

          <v-form
            ref="form"
            class="mt-5"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="subscription.price"
                  required
                  label="Стоимость *"
                  :error-messages="fieldError('price')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="subscription.lessons_count"
                  required
                  label="Число занятий"
                  :error-messages="fieldError('lessons_count')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  ref="startDateMenu"
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  min-width="290px"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      :value="formatDate(subscription.start_date)"
                      label="с *"
                      :error-messages="fieldError('start_date')"
                      color="secondary"
                      prepend-icon="mdi-calendar-outline"
                      messages="Дата начала действия абонемента"
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="subscription.start_date"
                    color="secondary"
                    locale="ru-ru"
                    landscape
                    scrollable
                    first-day-of-week="1"
                    @input="startDateMenu = false"
                  >
                    <v-spacer />

                    <v-btn
                      color="secondary"
                      large
                      @click="startDateMenu = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  ref="endDateMenu"
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  min-width="290px"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      :value="formatDate(subscription.end_date)"
                      label="по *"
                      :error-messages="fieldError('end_date')"
                      color="secondary"
                      prepend-icon="mdi-calendar-outline"
                      messages="Дата окончания действия абонемента (включительно)"
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="subscription.end_date"
                    color="secondary"
                    locale="ru-ru"
                    landscape
                    scrollable
                    first-day-of-week="1"
                    @input="endDateMenu = false"
                  >
                    <v-spacer />

                    <v-btn
                      color="secondary"
                      large
                      @click="endDateMenu = false"
                    >
                      Отмена
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="subscription.timetable_id"
                  label="Группа по расписанию"
                  :items="timetables"
                  :error-messages="fieldError('timetable_id')"
                  item-value="id"
                  item-text="name"
                  :disabled="subscription.timetable_id && subscription.lessons && subscription.lessons.length > 0"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-textarea
                  v-model="subscription.comment"
                  :error-messages="fieldError('comment')"
                  rows="1"
                  auto-grow
                  label="Комментарий"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-actions class="pl-0">
                  <v-row
                    class="pa-0 ml-2"
                  >
                    <v-col
                      cols="auto"
                      class="pa-0"
                    >
                      <v-btn
                        color="success"
                        min-width="100"
                        :disabled="loading"
                        @click="saveSubscription()"
                      >
                        Сохранить
                      </v-btn>
                    </v-col>
                    <!--
                  <v-btn
                    v-if="subscription.payments.length === 0 && subscription.bills.length == 1"
                    color="error"
                    class="ma-1"
                    min-width="100"
                    @click="confirmDialog = true"
                  >
                    Удалить
                  </v-btn>
                  -->
                    <v-col
                      cols="auto"
                      class="pa-0"
                    >
                      <v-btn
                        color="warning"
                        min-width="100"
                        :disabled="loading"
                        @click="changePrice()"
                      >
                        Стоимость
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pa-0"
                    >
                      <v-btn
                        v-if="subscription.status !== -2"
                        color="error"
                        min-width="100"
                        :disabled="loading"
                        @click="createRefund()"
                      >
                        Возврат
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pa-0"
                    >
                      <v-btn
                        v-if="subscription.status !== -2"
                        color="info"
                        min-width="100"
                        :disabled="loading"
                        @click="createDopSubscription()"
                      >
                        Допзанятия
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        lg="6"
      >
        <base-material-card
          v-if="!loading"
          color="indigo"
          icon="mdi-cash"
          title="Оплаты и списания по абонементу"
          class="px-5 py-3"
        >
          <base-material-alert
            v-if="subscription.balance < 0"
            color="error"
            dark
            icon="mdi-alert-box"
            class="mb-0"
          >
            <strong>Задолженность по абонементу:</strong>
            <v-chip
              color="red"
              class="ml-2"
            >
              {{ Math.abs(subscription.balance) }}
            </v-chip>
          </base-material-alert>

          <base-material-alert
            v-if="subscription.balance > 0"
            color="warning"
            dark
            icon="mdi-alert-box"
            class="mb-0"
          >
            <strong>Переплата по абонементу:</strong>
            <v-chip
              color="red"
              class="ml-2"
            >
              {{ Math.abs(subscription.balance) }}
            </v-chip>
          </base-material-alert>

          <base-material-alert
            v-if="subscription.balance == 0"
            color="success"
            dark
            icon="mdi-alert-box"
            class="mb-0"
          >
            <strong>Задолженности нет.</strong>
          </base-material-alert>

          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="paymentsHeaders"
            :items="subscription.paymentsAndBills"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items-per-page="-1"
          >
            <template v-slot:item.timetable_id="{ item }">
              <span v-if="item.timetable">{{ item.timetable.name }}</span>
              <span v-else>#{{ item.timetable_id }}</span>
            </template>
            <template v-slot:item.created_date="{ item }">
              {{ item.created_date | formatDateFromUnixTime }}
            </template>
            <template v-slot:body.append="{headers}">
              <tr>
                <td class="pt-2 pb-10" />
                <td class="pt-2 pb-10 text--disabled text-start">
                  всего:
                </td>
                <td class="pt-2 pb-10 text--disabled">
                  {{ subscription.billsAmount }}
                </td>
                <td class="pt-2 pb-10 text--disabled">
                  {{ subscription.paymentsAmount }}
                </td>
                <td class="pt-2 pb-10 text--disabled">
                  <!--<span class="mr-4">Баланс:</span>{{subscription.balance}}-->
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-card-actions class="pl-0">
            <v-btn
              v-if="subscription.status !== -2"
              min-width="100"
              color="secondary"
              :disabled="loading"
              @click="createPayment()"
            >
              <v-icon>mdi-cash</v-icon>
              Внести оплату
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        lg="10"
      >
        <base-material-card
          v-if="!loading"
          color="info"
          icon="mdi-account-check"
          title="Занятия в период действия абонемента"
          class="px-5 py-3"
        >
          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="lessonsHeaders"
            :items="timetablesAndLessons"
            :sort-by="['start']"
            :sort-desc="[false]"
            :items-per-page="-1"
            @click:row="lessonRowClick"
          >
            <template v-slot:item.extendedProps.lesson="{ item }">
              <span v-if="item.extendedProps.lesson && !item.extendedProps.canceled">
                <v-icon
                  :color="item.color"
                >
                  mdi-check
                </v-icon>Да
              </span>
              <span v-else-if="item.extendedProps.lesson && item.extendedProps.canceled">
                <v-icon
                  color="red"
                >
                  mdi-cancel
                </v-icon>Отменено
              </span>
              <span v-else>
                <v-icon
                  :color="item.color"
                >
                  mdi-close-outline
                </v-icon>Нет
              </span>
            </template>
            <template v-slot:item.start="{ item }">
              {{ lessonDateTitle(item.extendedProps) }}
            </template>
            <template v-slot:item.extendedProps.id="{ item }">
              <span v-if="item.extendedProps.id && isAttended(item.extendedProps.id)">
                <v-icon
                  :color="item.color"
                >
                  mdi-check
                </v-icon>Да
              </span>
              <span
                v-else-if="item.extendedProps.id && isAnotherSubscriptionLesson(item.extendedProps.id)"
                title="Занятие было отмечено в другом абонементе"
              >
                <v-icon
                  color="info"
                >
                  mdi-check-outline
                </v-icon>Да (аб.#{{ getAnotherSubscriptionIdLesson(item.extendedProps.id) }})
              </span>
              <span v-else-if="item.extendedProps.lesson">
                <v-icon
                  color="red"
                >
                  mdi-close
                </v-icon>Нет
              </span>
              <span v-else>
                <v-icon
                  :color="item.color"
                >
                  mdi-close-outline
                </v-icon>Нет
              </span>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="paymentDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Оплата абонемента #{{ subscription.id }} {{ subscription.client.name }}

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="paymentDialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1 text-center">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                ref="form"
                class="mt-5"
                lazy-validation
              >
                <v-text-field
                  v-model="payment.payment"
                  required
                  label="Сумма"
                  :error-messages="fieldError('payment')"
                />

                <v-text-field
                  v-model="payment.comment"
                  label="Примечание"
                  :error-messages="fieldError('comment')"
                />
              </v-form>
            </v-col>
          </v-row>

          <v-btn
            class="ma-1"
            color="secondary"
            rounded
            min-width="100"
            :disabled="loading"
            @click="savePayment()"
          >
            Сохранить
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить абонемент?
        </v-card-title>

        <v-card-text>
          Запись будет удалена без возможности восстановления.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            :disabled="loading"
            @click="removeSubscription(subscription.id)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="lessonDialog"
      max-width="700"
    >
      <v-card>
        <v-card-title>
          <div v-if="lesson.id && lesson.canceled">
            Запись об отмене <span v-if="lesson.timetable && lesson.timetable.online">онлайн </span>занятия #{{ lesson.id }}
          </div>
          <div v-else-if="lesson.id">
            Запись о<span v-if="lesson.timetable && lesson.timetable.online">б онлайн</span> занятии #{{ lesson.id }}
          </div>
          <div v-else-if="lesson.canceled">
            Добавить запись об отмене <span v-if="lesson.timetable && lesson.timetable.online">онлайн </span>занятия
          </div>
          <div v-else>
            Добавить запись о<span v-if="lesson.timetable && lesson.timetable.online">б онлайн</span> занятии
          </div>
          <v-spacer />
          <v-icon
            aria-label="Close"
            @click="lessonDialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1 text-center pt-0">
          <v-row>
            <v-col
              class="pt-0"
              cols="12"
              md="12"
            >
              <v-form
                ref="form"
                class="mt-0"
                lazy-validation
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="lesson.datetime"
                      required
                      label="Дата и время начала *"
                      :error-messages="fieldError('datetime')"
                      :disabled="true"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-checkbox
                      v-model="lesson.canceled"
                      required
                      label="Занятие отменено"
                      :false-value="0"
                      :true-value="1"
                      :disabled="lesson.id && lesson.clients && lesson.clients.length > 0"
                      :error-messages="fieldError('canceled')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="lesson.timetable_id"
                      :disabled="true"
                      label="Группа"
                      :items="timetables"
                      :error-messages="fieldError('timetable_id')"
                      item-value="id"
                      item-text="name"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="lesson.timetable_schedule_id"
                      :disabled="true"
                      label="Расписание"
                      :items="schedules"
                      :error-messages="fieldError('timetable_schedule_id')"
                      item-value="id"
                      item-text="name"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="lesson.room_id"
                      label="Помещение *"
                      :items="rooms"
                      item-value="id"
                      item-text="name"
                      :error-messages="fieldError('room_id')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-textarea
                      v-model="lesson.comment"
                      :error-messages="fieldError('comment')"
                      rows="1"
                      auto-grow
                      label="Комментарий"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-select
                      v-model="lesson.teachersIds"
                      :error-messages="fieldError('teachers')"
                      color="secondary"
                      item-color="secondary"
                      label="Выбрать преподавателей"
                      item-value="id"
                      item-text="name"
                      multiple
                      :items="teachers"
                    >
                      <template v-slot:item="{ attrs, item, on }">
                        <v-list-item
                          v-bind="attrs"
                          active-class="secondary elevation-4 white--text"
                          class="mx-3 mb-2 v-sheet"
                          elevation="0"
                          v-on="on"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              item-value="item.id"
                              return-object
                              v-text="item.name"
                            />
                          </v-list-item-content>

                          <v-scale-transition>
                            <v-list-item-icon
                              v-if="attrs.inputValue"
                              class="my-3"
                            >
                              <v-icon>mdi-check</v-icon>
                            </v-list-item-icon>
                          </v-scale-transition>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row v-if="lesson.timetable && lesson.timetable.online">
                  <v-col
                    cols="12"
                    lg="8"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="lesson.zoom_url"
                      label="Ссылка-приглашение на конференцию"
                      :error-messages="fieldError('zoom_url')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="lesson.zoom_password"
                      label="Пароль от конференции"
                      :error-messages="fieldError('zoom_password')"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <base-material-card
                      dark
                      color="success"
                      icon="mdi-account-multiple-check"
                      inline
                      title="Присутствовали на занятии"
                      class="px-5 py-3"
                    >
                      <v-data-table
                        v-model="lesson.clients"
                        :headers="clientsHeaders"
                        :items="clients"
                        :sort-by="['name']"
                        disable-pagination
                        hide-default-footer
                        show-select
                      >
                        <template v-slot:item.name="{ item }">
                          <span
                            v-if="item.name"
                            title="Открыть карточку клиента"
                            class="client-pointer"
                            @click="singleClient(item.id)"
                          >
                            {{ item.name }} #{{ item.id }}
                          </span>
                          <span
                            v-else-if="item.parents"
                            title="Открыть карточку клиента"
                            class="client-pointer"
                            @click="singleClient(item.id)"
                          >
                            {{ item.parents }} #{{ item.id }}
                          </span>
                          <span
                            v-else
                            title="Открыть карточку клиента"
                            class="client-pointer"
                            @click="singleClient(item.id)"
                          >
                            #{{ item.id }}
                          </span>
                        </template>

                        <template v-slot:item.activeSubscriptions="{ item }">
                          <div v-if="!isCheckedClient(item)">
                            <base-material-alert
                              v-if="isMultiplyActiveSubscriptions(item.activeSubscriptions) && !isOtherActiveSubscriptionInPriority(item.activeSubscriptions, subscription, item)"
                              color="pink darken-1"
                              class="pt-1 pb-1 mt-1 mb-1"
                            >
                              <v-icon class="mr-2">
                                mdi-alert-decagram-outline
                              </v-icon>
                              <span class="font-weight-light caption">На данное занятие доступны несколько абонементов.
                                Занятие будет отмечено в первом по приоритету <span v-if="isCurrentClient(item)">текущем</span> абонементе #{{ item.activeSubscriptions[0].id }}.
                                Более приоритетным является абонемент у которого остались неиспользованные занятия и дата окончания наступает раньше.</span>
                            </base-material-alert>

                            <base-material-alert
                              v-if="!isMultiplyActiveSubscriptions(item.activeSubscriptions) && isOtherActiveSubscriptionInPriority(item.activeSubscriptions, subscription, item)"
                              color="pink darken-1"
                              class="pt-1 pb-1 mt-1 mb-1"
                            >
                              <v-icon class="mr-2">
                                mdi-alert-decagram-outline
                              </v-icon>
                              <span class="font-weight-light caption">Занятие будет отмечено в более приоритетном абонементе #{{ item.activeSubscriptions[0].id }}
                                <span v-if="isCurrentClient(item)">, вместо текущего абонемента #{{ subscription.id }}</span>.
                                Более приоритетным является абонемент у которого остались неиспользованные занятия и дата окончания наступает раньше.</span>
                            </base-material-alert>

                            <base-material-alert
                              v-if="isMultiplyActiveSubscriptions(item.activeSubscriptions) && isOtherActiveSubscriptionInPriority(item.activeSubscriptions, subscription, item)"
                              color="pink darken-1"
                              class="pt-1 pb-1 mt-1 mb-1"
                            >
                              <v-icon class="mr-2">
                                mdi-alert-decagram-outline
                              </v-icon>
                              <span class="font-weight-light caption">На данное занятие доступны несколько абонементов.
                                Занятие будет отмечено в более приоритетном абонементе #{{ item.activeSubscriptions[0].id }}<span v-if="isCurrentClient(item)">, вместо текущего абонемента #{{ subscription.id }}</span>.
                                Более приоритетным является абонемент у которого остались неиспользованные занятия и дата окончания наступает раньше.</span>
                            </base-material-alert>
                          </div>

                          <div
                            v-for="(activeSubscription, index) in item.activeSubscriptions"
                            :key="activeSubscription.id"
                          >
                            <v-chip
                              class="abo-chip"
                              :class="{ 'abo-chip-first': index===0, 'abo-chip-other': index !== 0}"
                              :color="activeSubscription.extendedProps.colorClass"
                              :title="subscriptionTitle(activeSubscription.id)"
                              @click="singleSubscription(activeSubscription.id)"
                            >
                              [{{ activeSubscription.start_date }}:{{ activeSubscription.end_date }}] [#{{ activeSubscription.id }}] {{ activeSubscription.title }}
                            </v-chip>
                          </div>
                        </template>

                        <template v-slot:footer>
                          <v-card-text>
                            <v-row
                              align="center"
                              justify="end"
                            >
                              <strong class="title">Присутствовали</strong>

                              <div class="display-1 font-weight-light ml-12">
                                {{ lesson.clients.length }}
                              </div>
                            </v-row>
                          </v-card-text>
                        </template>
                      </v-data-table>
                    </base-material-card>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-btn
            class="ma-1"
            rounded
            min-width="100"
            @click="lessonDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            class="ma-1"
            rounded
            color="secondary"
            min-width="100"
            :disabled="loading"
            @click="saveLesson()"
          >
            Сохранить
          </v-btn>

          <v-btn
            v-if="lesson.id"
            class="ma-1"
            rounded
            color="error"
            min-width="100"
            :disabled="loading"
            @click="confirmLessonDialog = true"
          >
            Удалить
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmLessonDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить запись?
        </v-card-title>

        <v-card-text>
          Запись будет удалена без возможности восстановления.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmLessonDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            :disabled="loading"
            @click="removeLesson(lesson.id)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="refundDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Возврат абонемента

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="refundDialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1 text-center pt-0">
          <v-row
            class="pt-0"
          >
            <v-col
              class="pt-0"
              cols="12"
              md="12"
            >
              <v-form
                ref="form"
                class="mt-5"
                lazy-validation
              >
                <v-text-field
                  v-model="refundCount"
                  required
                  :error-messages="fieldError('bill')"
                  label="Число занятий к возврату"
                  @keyup="refundCountChanged"
                />

                <v-text-field
                  v-model="refundMoneyValue"
                  required
                  label="Сумма возрата средств клиенту"
                  :error-messages="fieldError('payment')"
                  messages="Не может превышать сумму оплаты, внесенную за данный абонемент"
                />

                <v-text-field
                  v-model="refundComment"
                  label="Примечание"
                  :error-messages="fieldError('comment')"
                />
              </v-form>
            </v-col>
          </v-row>

          <v-btn
            class="ma-1"
            color="secondary"
            rounded
            min-width="100"
            :disabled="loading"
            @click="submitRefund()"
          >
            Сделать возврат
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dopSubscriptionDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Создать абонемент {{ subscription.client.name }}
          <div
            class="subtitle-2"
          >
            для компенсации пропущенных занятий
          </div>
          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="dopSubscriptionDialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1 text-center pt-0">
          <v-row>
            <v-col
              class="pt-0"
              cols="12"
              md="12"
            >
              <v-form
                ref="form"
                class="mt-0"
                lazy-validation
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="dopSubscription.lessons_count"
                      required
                      label="Число занятий"
                      :error-messages="dopFieldError('lessons_count')"
                      messages="Кол-во зантий в исходном абонементе будет убавлено на данное кол-во"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-menu
                      ref="startDateMenu"
                      v-model="dopStartDateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="dopStartDate"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          :value="formatDate(dopSubscription.start_date)"
                          label="с *"
                          :error-messages="dopFieldError('start_date')"
                          color="secondary"
                          prepend-icon="mdi-calendar-outline"
                          messages="Дата начала действия абонемента"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        v-model="dopSubscription.start_date"
                        color="secondary"
                        locale="ru-ru"
                        landscape
                        scrollable
                        first-day-of-week="1"
                        @input="dopStartDateMenu = false"
                      >
                        <v-spacer />

                        <v-btn
                          color="secondary"
                          large
                          @click="dopStartDateMenu = false"
                        >
                          Cancel
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-menu
                      ref="endDateMenu"
                      v-model="dopEndDateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="dopEndDate"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          :value="formatDate(dopSubscription.end_date)"
                          label="по *"
                          :error-messages="dopFieldError('end_date')"
                          color="secondary"
                          prepend-icon="mdi-calendar-outline"
                          messages="Дата окончания действия абонемента (включительно)"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        v-model="dopSubscription.end_date"
                        color="secondary"
                        locale="ru-ru"
                        landscape
                        scrollable
                        first-day-of-week="1"
                        @input="dopEndDateMenu = false"
                      >
                        <v-spacer />

                        <v-btn
                          color="secondary"
                          large
                          @click="dopEndDateMenu = false"
                        >
                          Отмена
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-select
                      v-model="dopSubscription.timetable_id"
                      taggable
                      label="Группа по расписанию"
                      :items="timetables"
                      :error-messages="dopFieldError('timetable_id')"
                      item-value="id"
                      item-text="name"
                      no-data-text="Укажите группы у клиента, чтобы добавить их к абонементу для корректного учета посещений занятий"
                      @change="onDopTimetableChange($event)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-0"
                  >
                    <v-textarea
                      v-model="dopSubscription.comment"
                      class="pt-0"
                      :error-messages="dopFieldError('comment')"
                      rows="1"
                      auto-grow
                      label="Комментарий"
                    />
                  </v-col>
                  <v-col
                    v-if="dopLessonsByDates.length > 0"
                    cols="12"
                    md="12"
                  >
                    Занятий по расписанию в выбранный период: <strong>{{ dopLessonsByDates.length }}</strong>
                  </v-col>
                  <v-col
                    v-if="dopLessonsByDates.length > 0"
                    cols="12"
                    md="12"
                  >
                    <v-chip
                      v-for="lesson in dopLessonsByDates"
                      :key="lesson"
                      small
                    >
                      {{ lesson }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-btn
            class="ma-1"
            rounded
            color="secondary"
            min-width="100"
            :disabled="loading"
            @click="saveDopSubscription()"
          >
            Сохранить
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="changePriceDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Изменение стоимости абонемента

          <v-spacer />
          <v-icon
            aria-label="Close"
            @click="changePriceDialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <base-material-alert
                dark
                color="warning"
                dismissible
              >
                <span class="text-start body-2">
                  Внимание! В зависимости от направления изменения стоимости будет создано либо списание, либо возврат списания,
                  что может привести к появлению задолженности или переплаты.
                  Производите данную опрерацию только в случае необходимости корректировки ошибок, и если вы понимаете что делаете.
                  В обычных случаях лучше создавать новый, отдельный абонемент или обратится за консультацией.
                </span>
              </base-material-alert>
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="text-center"
            >
              <v-form
                ref="form"
                class="mt-5"
                lazy-validation
              >
                <v-text-field
                  v-model="changePriceOldValue"
                  disabled
                  label="Текущая стоимость"
                />

                <v-text-field
                  v-model="changePriceNewValue"
                  label="Новая стоимость"
                  :error-messages="fieldError('newPrice')"
                />
              </v-form>
            </v-col>
          </v-row>

          <v-btn
            class="ma-1"
            color="secondary"
            rounded
            min-width="100"
            :disabled="loading"
            @click="submitChangePrice()"
          >
            ИЗМЕНИТЬ
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="anotherSubscriptionDialog"
      max-width="300"
    >
      <v-card>
        <v-card-text>
          Это занятие было отмечено в другом абонементе #{{ anotherSubscriptionId }}.<br><br>
          Перейти к этому абонементу?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="anotherSubscriptionDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="singleSubscription(anotherSubscriptionId)"
          >
            Перейти
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
  .v-data-table tr, .cursor-pointer {
    cursor: pointer;
  }

  .client-title-link:hover {
    text-decoration: underline;
  }

  .abo-chip {
    height: auto !important;
    margin-bottom: 1px !important;
  }

  .abo-chip-other {
    opacity: 0.5;
  }
</style>

<script>
  import timetableApi from '@/services/TimetablesApi'
  import subscriptionApi from '@/services/SubscriptionsApi'
  import moment from 'moment'
  import clientsApi from '../services/ClientsApi'
  import lessonsApi from '../services/LessonsApi'
  import roomsApi from '../services/RoomsApi'
  import teachersApi from '../services/TeachersApi'
  import subscriptionsApi from '../services/SubscriptionsApi'
  import scheduleApi from '../services/SchedulesApi'

  export default {
    filters: {
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('DD.MM.YYYY HH:mm')
      },
    },
    props: ['id'],
    data () {
      return {
        templates: [],
        dopSubscriptionDialog: false,
        dopStartDateMenu: false,
        dopEndDateMenu: false,
        dopStartDate: '',
        dopEndDate: '',
        dopInvalidateCounter: 1,
        dopSubscription: {
          id: null,
          client: { name: '' },
          start_date: null,
          end_date: null,
          lessons_count: null,
          price: 0,
          timetable: null,
          timetable_id: null,
          lessons: [],
          payments: [],
        },
        dopSelectedTemplate: null,
        dopFieldsErrors: [],
        dopFieldError (fieldName) {
          let errors = []
          if (this.dopFieldsErrors) {
            this.dopFieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },

        subscription: {
          id: null,
          client: { name: '' },
          start_date: null,
          end_date: null,
          lessons_count: null,
          price: null,
          timetable: null,
          timetable_id: null,
          lessons: [],
          payments: [],
          bills: [],
          paymentsAndBills: [],
          billsAmount: null,
          paymentsAmount: null,
        },
        loading: true,
        timetables: [{ id: null, name: ' - не выбрано (опционально) - ' }],
        schedules: [{ id: null, name: ' - не выбрано - ', tag: null }],

        error: null,
        fieldsErrors: [],
        startDateMenu: false,
        endDateMenu: false,
        startDate: '',
        endDate: '',

        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        // Платежи
        paymentsHeaders: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: true,
            text: 'Дата оплаты',
            value: 'created_date',
          },
          {
            sortable: true,
            text: 'Списания',
            value: 'bill',
          },
          {
            sortable: true,
            text: 'Оплаты',
            value: 'payment',
          },
          {
            sortable: true,
            text: 'Примечание',
            value: 'comment',
          },
        ],
        // Занятия
        lessonsHeaders: [
          {
            sortable: true,
            text: 'Дата занятия',
            value: 'start',
          },
          {
            sortable: true,
            text: 'Название',
            value: 'title',
          },
          {
            sortable: false,
            text: 'Присутствовал',
            value: 'extendedProps.id',
          },
          {
            sortable: false,
            text: 'Проведено',
            value: 'extendedProps.lesson',
          },
          {
            sortable: false,
            text: 'Присутствовало',
            value: 'extendedProps.studentsCount',
          },
        ],

        payment: {
          bill: 0,
          payment: null,
          comment: null,
        },
        paymentDialog: false,
        confirmDialog: false,

        timetablesAndLessons: [],

        lessonDialog: false,

        confirmLessonDialog: false,
        clients: [],
        lesson: {
          datetime: null,
          room_id: null,
          teachers: [],
          timetable_id: null,
          comment: null,
          clients: [],
        },
        rooms: [],
        teachers: [],

        clientsHeaders: [
          {
            sortable: true,
            text: 'Имя',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Абонемент',
            value: 'activeSubscriptions',
          },
        ],

        refundDialog: false,
        refundValue: 0,
        refundFull: true,
        refundCount: 0,
        refundMoney: false,
        refundMoneyValue: 0,
        refundComment: 0,

        changePriceDialog: false,
        changePriceNewValue: null,
        changePriceOldValue: null,

        cls: [],

        anotherSubscriptionDialog: false,
        anotherSubscriptionId: null,
      }
    },

    computed: {
      dopLessonsByDates () {
        if (this.dopInvalidateCounter && this.dopSubscription.start_date && this.dopSubscription.end_date && this.dopSubscription.timetable_id) {
          let lessons = []
          let start = new Date(this.dopSubscription.start_date)
          let end = new Date(this.dopSubscription.end_date)
          let timetableId = this.dopSubscription.timetable_id
          for (var i = 0; i < this.timetables.length; i++) {
            if (timetableId === this.timetables[i].id) {
              var timetable = this.timetables[i]
              break
            }
          }

          var loop = new Date(start)
          while (loop <= end) {
            if (moment(loop).isoWeekday() === 1 && timetable.pn === 1) {
              lessons.push('ПН ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_pn.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 2 && timetable.vt === 1) {
              lessons.push('ВТ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_vt.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 3 && timetable.sr === 1) {
              lessons.push('СР ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_sr.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 4 && timetable.ch === 1) {
              lessons.push('ЧТ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_ch.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 5 && timetable.pt === 1) {
              lessons.push('ПТ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_pt.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 6 && timetable.sb === 1) {
              lessons.push('СБ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_sb.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 7 && timetable.vs === 1) {
              lessons.push('ВС ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_vs.slice(0, -3))
            }
            var newDate = loop.setDate(loop.getDate() + 1)
            loop = new Date(newDate)
          }
          return lessons
        }
        return []
      },
    },

    mounted () {
      document.title = 'Абонемент #' + this.id + ' | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      this.fetchData()
    },

    methods: {

      fetchData () {
        this.loading = true
        subscriptionApi
          .fetchSubscription(this.id)
          .then(response => {
            this.subscription = response
            document.title = 'Абонемент #' + this.id + ' | ' + this.subscription.client.name + ' | ' + process.env.VUE_APP_NAME
            this.timetables = this.subscription.client.timetables
            if (this.subscription.timetable_id) {
              timetableApi
                .fetchTimetableByDates(this.subscription.timetable_id, this.subscription.start_date, this.subscription.end_date)
                .then(response => {
                  this.timetablesAndLessons = response
                  let lessonsIds = []
                  this.timetablesAndLessons.forEach((item) => {
                    if (item.extendedProps.lesson) {
                      lessonsIds.push(item.extendedProps.id)
                    }
                  })
                  subscriptionApi.fetchCLS([this.subscription.student_id], lessonsIds)
                    .then(response => {
                      this.cls = response
                      this.loading = false
                    }).catch(error => {
                      this.handleError(error)
                    })
                }).catch(error => {
                  this.handleError(error)
                })
            } else {
              this.loading = false
            }
          }).catch(error => {
            this.handleError(error)
          })

        roomsApi
          .fetchRooms()
          .then(response => {
            this.rooms = response
          })
          .catch(error => {
            this.handleError(error)
          })

        teachersApi
          .fetchActiveTeachers()
          .then(response => {
            this.teachers = response
          })
          .catch(error => {
            this.handleError(error)
          })

        subscriptionsApi
          .fetchSubscriptionTemplates()
          .then(response => {
            this.templates = response
          })
          .catch(error => {
            this.handleError(error)
          })
      },

      back () {
        this.$router.push('/')
      },
      saveSubscription () {
        this.loading = true
        if (this.subscription.id) {
          this.error = null
          this.fieldsErrors = []
          subscriptionApi
            .updateSubscription(this.subscription)
            .then(response => {
              this.subscription = response
              this.fetchData()
            })
            .catch(error => {
              this.handleError(error)
            })
        } else {
          subscriptionApi
            .createSubscription(this.subscription)
            .then(response => {
              this.subscription = response
              this.fetchData()
            })
            .catch(error => {
              this.handleError(error)
            })
        }
      },
      removeSubscription () {
        this.loading = true
        this.confirmDialog = false
        subscriptionApi
          .removeSubscription(this.subscription.id)
          .then(response => {
            // this.subscription = response
            this.loading = false
            this.$router.go(-1)
          }).catch(error => {
            this.handleError(error)
          })
      },

      loadSubscription () {
        subscriptionApi
          .fetchSubscription(this.id)
          .then(response => {
            this.subscription = response
            this.loading = false
          }).catch(error => {
            this.handleError(error)
          })
      },

      loadPayment (item) {
        subscriptionApi
          .fetchSubscriptionPayment(item.id)
          .then(response => {
            this.payment = response
            this.paymentDialog = true
          })
      },
      createPayment () {
        this.error = null
        this.fieldsErrors = []
        this.payment = {}
        this.payment.bill = 0
        this.payment.client_id = this.subscription.client.id
        this.payment.subscription_id = this.subscription.id
        this.paymentDialog = true
      },
      savePayment () {
        this.loading = true
        this.error = null
        this.fieldsErrors = []

        if (this.payment.id) {
          subscriptionApi
            .updateSubscriptionPayment(this.payment)
            .then(response => {
              this.payment = response
              this.paymentDialog = false
              this.loadSubscription()
            })
            .catch(error => {
              this.handleError(error)
              this.loadSubscription()
            })
        } else {
          subscriptionApi
            .createSubscriptionPayment(this.payment)
            .then(response => {
              this.payment = response
              this.paymentDialog = false
              this.loadSubscription()
            })
            .catch(error => {
              this.handleError(error)
              this.loadSubscription()
            })
        }
      },

      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },

      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },

      isAttended (lessonId) {
        return this.subscription.lessons.filter((item) => item.id === lessonId).length > 0
      },

      isAnotherSubscriptionLesson (lessonId) {
        const subscriptionId = +this.subscription.id
        return this.cls.filter((item) => item.lesson_id === lessonId && item.subscription_id !== subscriptionId).length > 0
      },

      getAnotherSubscriptionIdLesson (lessonId) {
        const subscriptionId = +this.subscription.id
        const result = this.cls.filter((item) => item.lesson_id === lessonId && item.subscription_id !== subscriptionId)
        if (result.length > 0) {
          return result[0].subscription_id
        }

        return null
      },

      editClient (clientId) {
        this.$router.push('/client/' + clientId)
      },
      removeLesson (lessonId) {
        this.loading = true
        this.confirmLessonDialog = false
        lessonsApi
          .deleteLesson(lessonId)
          .then(response => {
            this.lessonDialog = false
            this.selectedOpen = false
            this.fetchData()
          })
          .catch(error => {
            this.handleError(error)
          })
      },

      lessonRowClick (item) {
        if (item.extendedProps.lesson) {
          if (this.isAnotherSubscriptionLesson(item.extendedProps.id)) {
            this.anotherSubscriptionId = this.getAnotherSubscriptionIdLesson(item.extendedProps.id)
            this.anotherSubscriptionDialog = true
          } else {
            this.editLesson(item.extendedProps.id, item.extendedProps.date)
          }
        } else {
          this.createLesson(item.extendedProps.timetableId, item.extendedProps.timetableScheduleId, item.start)
        }
      },

      editLesson (lessonId, lessonDate) {
        this.loading = true
        lessonsApi
          .fetchLesson(lessonId)
          .then(response => {
            this.lesson = response
            this.schedules = [this.lesson.schedule]
            if (this.lesson.teachersIds) {
              this.lesson.teachersIds = this.lesson.teachersIds.map(function (item) {
                return parseInt(item, 10)
              })
            }
            this.lessonDialog = true
            clientsApi.fetchClientsByTimetableId(this.lesson.timetable_id, lessonDate)
              .then(response => {
                let unprocessedClients = response

                let clses = this.lesson.clses
                this.clients = []

                // отображаем учеников с купленными абонементами или уже отмеченных
                for (var i = 0, len = unprocessedClients.length; i < len; i++) {
                  let client = unprocessedClients[i]
                  let clientChecked = this.lesson.clients.some(elem => elem.id === client.id)
                  let linkedCLS = clses.filter((item) => item.student_id === client.id)
                  if (linkedCLS.length > 0 && !client.activeSubscriptions.find(item => item.id === linkedCLS[0].subscription.id)) {
                    client.activeSubscriptions = []
                    client.activeSubscriptions.push(linkedCLS[0].subscription)
                  }
                  if (client.activeSubscriptions.length > 0 || clientChecked) {
                    this.clients.push(client)
                  }
                }
                this.loading = false
              })
              .catch(error => {
                this.handleError(error)
              })
          })
      },
      createLesson (timetableId, timetableScheduleId, dateTime) {
        this.loading = true

        scheduleApi.fetchSchedulesByTimetableId(timetableId)
          .then(response => {
            this.schedules = response
          })
          .catch(error => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.fieldsErrors = data
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
          })

        clientsApi.fetchClientsByTimetableId(timetableId, moment(dateTime).format('YYYY-MM-DD'))
          .then(response => {
            this.clients = []
            // отображаем только учеников с купленными абонементами
            for (var i = 0, len = response.length; i < len; i++) {
              let client = response[i]
              if (client.activeSubscriptions.length > 0) {
                this.clients.push(client)
              }
            }

            this.lesson = {}
            this.lesson.clients = []
            this.lesson.timetable_id = timetableId
            this.lesson.timetable_schedule_id = timetableScheduleId
            this.lesson.datetime = moment(dateTime).format('YYYY-MM-DD HH:mm:ss')

            let timetable = this.timetables.find(e => e.id === +timetableId)
            if (timetable) {
              if (timetable.room_id) this.lesson.room_id = timetable.room_id
              if (timetable.teacher_id) this.lesson.teachersIds = [timetable.teacher_id]
              this.lesson.timetable = timetable
            }

            this.lessonDialog = true
            this.loading = false
          })
          .catch(error => {
            this.handleError(error)
          })
      },
      saveLesson () {
        this.loading = true
        if (this.lesson.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          if (this.lesson.clients) {
            this.lesson.clientsIds = this.lesson.clients.map(c => c.id)
          }

          lessonsApi
            .updateLesson(this.lesson)
            .then(response => {
              this.lesson = response
              this.lessonDialog = false
              this.fetchData()
            })
            .catch(error => {
              this.handleError(error)
              this.fetchData()
            })
        } else {
          if (this.lesson.clients) {
            this.lesson.clientsIds = this.lesson.clients.map(c => c.id)
          }
          lessonsApi
            .createLesson(this.lesson)
            .then(response => {
              this.lesson = response
              this.lessonDialog = false
              this.fetchData()
            })
            .catch(error => {
              this.handleError(error)
              this.fetchData()
            })
        }
      },

      changePrice () {
        this.changePriceDialog = true
        this.changePriceOldValue = this.subscription.price
        this.changePriceNewValue = this.subscription.price
      },

      submitChangePrice () {
        this.loading = true
        if (this.changePriceOldValue !== this.changePriceNewValue) {
          subscriptionApi.changeSubscriptionPrice(this.subscription.id, this.changePriceNewValue)
            .then(response => {
              this.changePriceDialog = false
              this.fetchData()
            })
            .catch(error => {
              this.handleError(error)
            })
        }
      },

      createRefund () {
        // Полный возврат остатка по умолчанию
        let factLessonsCount = this.subscription.lessons.length
        let lessonsCount = this.subscription.lessons_count
        let lessonPrice = this.subscription.price / lessonsCount

        this.refundCount = lessonsCount - factLessonsCount
        this.refundValue = lessonPrice * this.refundCount

        this.refundMoneyValue = this.subscription.paymentsAmount - (factLessonsCount * lessonPrice)

        if (this.refundMoneyValue < 0) {
          this.refundMoneyValue = 0
        }

        this.refundComment = null
        this.refundDialog = true
        this.fieldsErrors = []
        this.error = null
      },

      submitRefund () {
        this.loading = true

        let refund = {}
        refund.bill = -Math.abs(this.refundValue)
        refund.payment = -Math.abs(this.refundMoneyValue)

        refund.subscription_id = this.subscription.id
        refund.client_id = this.subscription.client.id
        refund.comment = this.refundComment

        subscriptionApi
          .createSubscriptionPayment(refund)
          .then(response => {
            this.refundDialog = false
            // уменьшаем количество занятий в абонементе и его стоимость согласно списаниям
            this.subscription.lessons_count = this.subscription.lessons_count - this.refundCount
            this.subscription.price = this.subscription.price - this.refundValue
            subscriptionApi
              .updateSubscription(this.subscription)
              .then(response => {
                this.fetchData()
              })
              .catch(error => {
                this.handleError(error)
              })
          })
          .catch(error => {
            this.handleError(error)
          })
      },

      refundCountChanged () {
        this.fieldsErrors = []
        if (this.refundCount > this.subscription.lessons_count) {
          let error = { field: 'bill', message: 'Вы не можете вернуть занятий больше чем предусмотрено абонементом' }
          this.fieldsErrors.push(error)
        }

        let lessonPrice = this.subscription.price / this.subscription.lessons_count
        this.refundValue = lessonPrice * this.refundCount

        let factLessonsCount = this.subscription.lessons.length

        if ((parseInt(this.refundCount) + parseInt(factLessonsCount)) <= this.subscription.lessons_count) {
          this.refundMoneyValue = this.subscription.paymentsAmount - (factLessonsCount * lessonPrice)
          if (this.refundMoneyValue < 0) {
            this.refundMoneyValue = 0
          }
        } else {
          let refund = this.refundCount * lessonPrice
          if (refund >= this.subscription.paymentsAmount) {
            this.refundMoneyValue = this.subscription.paymentsAmount
          } else {
            this.refundMoneyValue = refund
          }
        }
      },

      singleClient (clientId) {
        this.$router.push('/client/' + clientId)
      },

      lessonDateTitle (ext) {
        return moment(ext.date).format('DD.MM.YY') + ' | ' + moment(ext.date).format('ddd').toUpperCase() + ' | ' + ext.startTime + '_' + ext.endTime
      },

      onDopTimetableChange (event) {
        // костыль для исправления глюка, когда изменение дочернего свойства
        // в v-select не приводит к обновлению lessonsByDates
        this.dopInvalidateCounter++
      },

      createDopSubscription () {
        this.dopSubscription = {}
        this.dopSubscription.price = 0
        this.dopSubscription.student_id = this.subscription.student_id
        this.dopSubscription.comment = '![Абонемент для компенсации пропущенных занятий по абонементу id=' + this.subscription.id + ']'
        this.dopSubscription.timetable_id = this.subscription.timetable_id
        this.dopSubscriptionDialog = true
        this.dopFieldsErrors = []
      },

      saveDopSubscription () {
        let lessonsAvailable = this.subscription.lessons_count - this.subscription.lessons.length
        if (this.dopSubscription.lessons_count > lessonsAvailable) {
          this.dopFieldsErrors = [{
            field: 'lessons_count',
            message: 'Вы не можете компенсировать занятий больше, чем осталось по абонементу. Занятий осталось: ' + lessonsAvailable,
          }]
          return
        }
        this.loading = true
        subscriptionsApi
          .createSubscription(this.dopSubscription)
          .then(response => {
            this.dopSubscription = response
            this.dopSubscriptionDialog = false
            // Убавляем кол-во занятий в абонементе доноре
            let sub = this.subscription
            sub.lessons_count = this.subscription.lessons_count - this.dopSubscription.lessons_count
            subscriptionsApi
              .updateSubscription(sub)
              .then(response => {
                this.$router.push('/client/' + this.subscription.student_id)
              })
              .catch(error => {
                this.handleError(error)
                this.loading = false
              })
          })
          .catch(error => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.dopFieldsErrors = data
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
          })
      },

      handleError (errorResponse) {
        let data = errorResponse.response.data
        if (errorResponse.response.status === 422) {
          this.fieldsErrors = data
        } else if (errorResponse.response.status === 401) {
          this.error = 'Неверный логин или пароль! Войдите в систему заново!'
        } else if (data.message) {
          this.error = data.message
        } else {
          this.error = JSON.stringify(data, null, 2)
        }
        this.loading = false
      },

      singleSubscription (id) {
        if (id !== this.subscription.id) {
          this.$router.push('/subscription/' + id)
        }
      },

      subscriptionTitle (id) {
        if (id === this.subscription.id) { return 'Текущий абонемент #' + id }
        return 'Перейти к абонементу #' + id
      },

      isMultiplyActiveSubscriptions (activeSubscriptions) {
        return activeSubscriptions.length > 1
      },

      isOtherActiveSubscriptionInPriority (activeSubscriptions, subscription, client) {
        if (this.isCurrentClient(client)) {
          return activeSubscriptions.length > 0 && activeSubscriptions[0].id !== subscription.id
        }

        return false
      },

      isCheckedClient (client) {
        return this.lesson.clients.some(elem => elem.id === client.id)
      },

      isCurrentClient (client) {
        return this.subscription.client.id === client.id
      },

      clientNameBreadcrumb () {
        if (!this.subscription.client) return ''
        if (!this.subscription.client.name && !this.subscription.client.parents) return '#' + this.subscription.client.id
        return this.subscription.client.name ? this.subscription.client.name : this.subscription.client.parents
      },
    },
  }
</script>
