var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "client-forms", fluid: "", tag: "section" } },
    [
      _vm.loading
        ? _c("v-container", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    size: 150,
                    width: 8,
                    color: "green"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          !_vm.loading
            ? _c(
                "v-col",
                {
                  staticClass: "mt-0 mb-0 pt-0 pb-0",
                  attrs: { cols: "12", md: "12" }
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: {
                      items: [
                        { text: "Ваш центр", to: "/" },
                        { text: "Клиенты", to: "/clients" },
                        {
                          text: _vm.clientNameBreadcrumb(),
                          to: "/client/" + _vm.subscription.client.id
                        },
                        { text: "Абонемент #" + _vm.subscription.id }
                      ],
                      large: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: { color: "success", icon: "mdi-badge-account" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "after-heading",
                            fn: function() {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { right: "", "max-width": "50%" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "card-title font-weight-light"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Абонемент #" +
                                                      _vm._s(
                                                        _vm.subscription.id
                                                      ) +
                                                      "\n                "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "cursor-pointer"
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                  mdi-help-circle-outline\n                "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "cursor-pointer title text-uppercase client-title-link",
                                                      attrs: {
                                                        title:
                                                          "Перейти на карточку клиента"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.singleClient(
                                                            _vm.subscription
                                                              .client.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.subscription
                                                              .client.name
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      237472450
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        'Для каждого абонементы должна быть выбрана группа по расписанию, чтобы правильно отмечать присутствие на уроке и вести учет. Отметить присутствие можно на странице "Абонемента" или в раздел "Учебная сетка", где нужно выбрать дату и группу и отметить, кто был на уроке. Удобнее всего это сделать перед или сразу после занятия. При возврате абонемента деньги будут зачислены на счет клиента для оплаты других абонементов или услуг, если не выбрать сразу оформить возврат наличными, тогда деньги должны быть выданы клиенту.'
                                      )
                                    ])
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3388097466
                      )
                    },
                    [
                      _vm.subscription.status === -1
                        ? _c(
                            "base-material-alert",
                            {
                              staticClass: "mb-0",
                              attrs: {
                                color: "warning",
                                dark: "",
                                icon: "mdi-alert-outline"
                              }
                            },
                            [
                              _vm._v(
                                "\n          Абонемент закончился!\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.subscription.status === -2
                        ? _c(
                            "base-material-alert",
                            {
                              staticClass: "mb-0",
                              attrs: {
                                color: "info",
                                dark: "",
                                icon: "mdi-alert-outline"
                              }
                            },
                            [
                              _vm._v(
                                "\n          Был произведен возврат данного абонемента!\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "mt-5",
                          attrs: { "lazy-validation": "" }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Стоимость *",
                                      "error-messages": _vm.fieldError("price")
                                    },
                                    model: {
                                      value: _vm.subscription.price,
                                      callback: function($$v) {
                                        _vm.$set(_vm.subscription, "price", $$v)
                                      },
                                      expression: "subscription.price"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Число занятий",
                                      "error-messages": _vm.fieldError(
                                        "lessons_count"
                                      )
                                    },
                                    model: {
                                      value: _vm.subscription.lessons_count,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.subscription,
                                          "lessons_count",
                                          $$v
                                        )
                                      },
                                      expression: "subscription.lessons_count"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "startDateMenu",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "return-value": _vm.startDate,
                                        transition: "scale-transition",
                                        "min-width": "290px",
                                        "offset-y": ""
                                      },
                                      on: {
                                        "update:returnValue": function($event) {
                                          _vm.startDate = $event
                                        },
                                        "update:return-value": function(
                                          $event
                                        ) {
                                          _vm.startDate = $event
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        readonly: "",
                                                        value: _vm.formatDate(
                                                          _vm.subscription
                                                            .start_date
                                                        ),
                                                        label: "с *",
                                                        "error-messages": _vm.fieldError(
                                                          "start_date"
                                                        ),
                                                        color: "secondary",
                                                        "prepend-icon":
                                                          "mdi-calendar-outline",
                                                        messages:
                                                          "Дата начала действия абонемента"
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2779485477
                                      ),
                                      model: {
                                        value: _vm.startDateMenu,
                                        callback: function($$v) {
                                          _vm.startDateMenu = $$v
                                        },
                                        expression: "startDateMenu"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-date-picker",
                                        {
                                          attrs: {
                                            color: "secondary",
                                            locale: "ru-ru",
                                            landscape: "",
                                            scrollable: "",
                                            "first-day-of-week": "1"
                                          },
                                          on: {
                                            input: function($event) {
                                              _vm.startDateMenu = false
                                            }
                                          },
                                          model: {
                                            value: _vm.subscription.start_date,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.subscription,
                                                "start_date",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "subscription.start_date"
                                          }
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                large: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.startDateMenu = false
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Cancel\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "endDateMenu",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "return-value": _vm.endDate,
                                        transition: "scale-transition",
                                        "min-width": "290px",
                                        "offset-y": ""
                                      },
                                      on: {
                                        "update:returnValue": function($event) {
                                          _vm.endDate = $event
                                        },
                                        "update:return-value": function(
                                          $event
                                        ) {
                                          _vm.endDate = $event
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        readonly: "",
                                                        value: _vm.formatDate(
                                                          _vm.subscription
                                                            .end_date
                                                        ),
                                                        label: "по *",
                                                        "error-messages": _vm.fieldError(
                                                          "end_date"
                                                        ),
                                                        color: "secondary",
                                                        "prepend-icon":
                                                          "mdi-calendar-outline",
                                                        messages:
                                                          "Дата окончания действия абонемента (включительно)"
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2777325198
                                      ),
                                      model: {
                                        value: _vm.endDateMenu,
                                        callback: function($$v) {
                                          _vm.endDateMenu = $$v
                                        },
                                        expression: "endDateMenu"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-date-picker",
                                        {
                                          attrs: {
                                            color: "secondary",
                                            locale: "ru-ru",
                                            landscape: "",
                                            scrollable: "",
                                            "first-day-of-week": "1"
                                          },
                                          on: {
                                            input: function($event) {
                                              _vm.endDateMenu = false
                                            }
                                          },
                                          model: {
                                            value: _vm.subscription.end_date,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.subscription,
                                                "end_date",
                                                $$v
                                              )
                                            },
                                            expression: "subscription.end_date"
                                          }
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                large: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.endDateMenu = false
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Отмена\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Группа по расписанию",
                                      items: _vm.timetables,
                                      "error-messages": _vm.fieldError(
                                        "timetable_id"
                                      ),
                                      "item-value": "id",
                                      "item-text": "name",
                                      disabled:
                                        _vm.subscription.timetable_id &&
                                        _vm.subscription.lessons &&
                                        _vm.subscription.lessons.length > 0
                                    },
                                    model: {
                                      value: _vm.subscription.timetable_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.subscription,
                                          "timetable_id",
                                          $$v
                                        )
                                      },
                                      expression: "subscription.timetable_id"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "comment"
                                      ),
                                      rows: "1",
                                      "auto-grow": "",
                                      label: "Комментарий"
                                    },
                                    model: {
                                      value: _vm.subscription.comment,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.subscription,
                                          "comment",
                                          $$v
                                        )
                                      },
                                      expression: "subscription.comment"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "pl-0" },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "pa-0 ml-2" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "success",
                                                    "min-width": "100",
                                                    disabled: _vm.loading
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.saveSubscription()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Сохранить\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "warning",
                                                    "min-width": "100",
                                                    disabled: _vm.loading
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.changePrice()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Стоимость\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _vm.subscription.status !== -2
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        "min-width": "100",
                                                        disabled: _vm.loading
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.createRefund()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Возврат\n                    "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _vm.subscription.status !== -2
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "info",
                                                        "min-width": "100",
                                                        disabled: _vm.loading
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.createDopSubscription()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Допзанятия\n                    "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "indigo",
                        icon: "mdi-cash",
                        title: "Оплаты и списания по абонементу"
                      }
                    },
                    [
                      _vm.subscription.balance < 0
                        ? _c(
                            "base-material-alert",
                            {
                              staticClass: "mb-0",
                              attrs: {
                                color: "error",
                                dark: "",
                                icon: "mdi-alert-box"
                              }
                            },
                            [
                              _c("strong", [
                                _vm._v("Задолженность по абонементу:")
                              ]),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ml-2",
                                  attrs: { color: "red" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        Math.abs(_vm.subscription.balance)
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.subscription.balance > 0
                        ? _c(
                            "base-material-alert",
                            {
                              staticClass: "mb-0",
                              attrs: {
                                color: "warning",
                                dark: "",
                                icon: "mdi-alert-box"
                              }
                            },
                            [
                              _c("strong", [
                                _vm._v("Переплата по абонементу:")
                              ]),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ml-2",
                                  attrs: { color: "red" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        Math.abs(_vm.subscription.balance)
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.subscription.balance == 0
                        ? _c(
                            "base-material-alert",
                            {
                              staticClass: "mb-0",
                              attrs: {
                                color: "success",
                                dark: "",
                                icon: "mdi-alert-box"
                              }
                            },
                            [_c("strong", [_vm._v("Задолженности нет.")])]
                          )
                        : _vm._e(),
                      _c("v-data-table", {
                        attrs: {
                          loading: _vm.loading,
                          "loading-text": "Загрузка...",
                          headers: _vm.paymentsHeaders,
                          items: _vm.subscription.paymentsAndBills,
                          "sort-by": ["id"],
                          "sort-desc": [true],
                          "items-per-page": -1
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.timetable_id",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.timetable
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.timetable.name))
                                      ])
                                    : _c("span", [
                                        _vm._v("#" + _vm._s(item.timetable_id))
                                      ])
                                ]
                              }
                            },
                            {
                              key: "item.created_date",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("formatDateFromUnixTime")(
                                          item.created_date
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "body.append",
                              fn: function(ref) {
                                var headers = ref.headers
                                return [
                                  _c("tr", [
                                    _c("td", { staticClass: "pt-2 pb-10" }),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "pt-2 pb-10 text--disabled text-start"
                                      },
                                      [
                                        _vm._v(
                                          "\n                всего:\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "pt-2 pb-10 text--disabled"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.subscription.billsAmount
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "pt-2 pb-10 text--disabled"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.subscription.paymentsAmount
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _c("td", {
                                      staticClass: "pt-2 pb-10 text--disabled"
                                    })
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2812535209
                        )
                      }),
                      _c(
                        "v-card-actions",
                        { staticClass: "pl-0" },
                        [
                          _vm.subscription.status !== -2
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "min-width": "100",
                                    color: "secondary",
                                    disabled: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.createPayment()
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-cash")]),
                                  _vm._v(
                                    "\n            Внести оплату\n          "
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "10" } },
            [
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "info",
                        icon: "mdi-account-check",
                        title: "Занятия в период действия абонемента"
                      }
                    },
                    [
                      _c("v-data-table", {
                        attrs: {
                          loading: _vm.loading,
                          "loading-text": "Загрузка...",
                          headers: _vm.lessonsHeaders,
                          items: _vm.timetablesAndLessons,
                          "sort-by": ["start"],
                          "sort-desc": [false],
                          "items-per-page": -1
                        },
                        on: { "click:row": _vm.lessonRowClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.extendedProps.lesson",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.extendedProps.lesson &&
                                  !item.extendedProps.canceled
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: item.color } },
                                            [
                                              _vm._v(
                                                "\n                mdi-check\n              "
                                              )
                                            ]
                                          ),
                                          _vm._v("Да\n            ")
                                        ],
                                        1
                                      )
                                    : item.extendedProps.lesson &&
                                      item.extendedProps.canceled
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "red" } },
                                            [
                                              _vm._v(
                                                "\n                mdi-cancel\n              "
                                              )
                                            ]
                                          ),
                                          _vm._v("Отменено\n            ")
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: item.color } },
                                            [
                                              _vm._v(
                                                "\n                mdi-close-outline\n              "
                                              )
                                            ]
                                          ),
                                          _vm._v("Нет\n            ")
                                        ],
                                        1
                                      )
                                ]
                              }
                            },
                            {
                              key: "item.start",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.lessonDateTitle(item.extendedProps)
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.extendedProps.id",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.extendedProps.id &&
                                  _vm.isAttended(item.extendedProps.id)
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: item.color } },
                                            [
                                              _vm._v(
                                                "\n                mdi-check\n              "
                                              )
                                            ]
                                          ),
                                          _vm._v("Да\n            ")
                                        ],
                                        1
                                      )
                                    : item.extendedProps.id &&
                                      _vm.isAnotherSubscriptionLesson(
                                        item.extendedProps.id
                                      )
                                    ? _c(
                                        "span",
                                        {
                                          attrs: {
                                            title:
                                              "Занятие было отмечено в другом абонементе"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "info" } },
                                            [
                                              _vm._v(
                                                "\n                mdi-check-outline\n              "
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            "Да (аб.#" +
                                              _vm._s(
                                                _vm.getAnotherSubscriptionIdLesson(
                                                  item.extendedProps.id
                                                )
                                              ) +
                                              ")\n            "
                                          )
                                        ],
                                        1
                                      )
                                    : item.extendedProps.lesson
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "red" } },
                                            [
                                              _vm._v(
                                                "\n                mdi-close\n              "
                                              )
                                            ]
                                          ),
                                          _vm._v("Нет\n            ")
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: item.color } },
                                            [
                                              _vm._v(
                                                "\n                mdi-close-outline\n              "
                                              )
                                            ]
                                          ),
                                          _vm._v("Нет\n            ")
                                        ],
                                        1
                                      )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2765263488
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.paymentDialog,
            callback: function($$v) {
              _vm.paymentDialog = $$v
            },
            expression: "paymentDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v(
                    "\n        Оплата абонемента #" +
                      _vm._s(_vm.subscription.id) +
                      " " +
                      _vm._s(_vm.subscription.client.name) +
                      "\n\n        "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.paymentDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1 text-center" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-5",
                              attrs: { "lazy-validation": "" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Сумма",
                                  "error-messages": _vm.fieldError("payment")
                                },
                                model: {
                                  value: _vm.payment.payment,
                                  callback: function($$v) {
                                    _vm.$set(_vm.payment, "payment", $$v)
                                  },
                                  expression: "payment.payment"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Примечание",
                                  "error-messages": _vm.fieldError("comment")
                                },
                                model: {
                                  value: _vm.payment.comment,
                                  callback: function($$v) {
                                    _vm.$set(_vm.payment, "comment", $$v)
                                  },
                                  expression: "payment.comment"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      attrs: {
                        color: "secondary",
                        rounded: "",
                        "min-width": "100",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.savePayment()
                        }
                      }
                    },
                    [_vm._v("\n          Сохранить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.confirmDialog,
            callback: function($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Удалить абонемент?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Запись будет удалена без возможности восстановления.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error darken-1",
                        text: "",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.removeSubscription(_vm.subscription.id)
                        }
                      }
                    },
                    [_vm._v("\n          Удалить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          model: {
            value: _vm.lessonDialog,
            callback: function($$v) {
              _vm.lessonDialog = $$v
            },
            expression: "lessonDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm.lesson.id && _vm.lesson.canceled
                    ? _c("div", [
                        _vm._v("\n          Запись об отмене "),
                        _vm.lesson.timetable && _vm.lesson.timetable.online
                          ? _c("span", [_vm._v("онлайн ")])
                          : _vm._e(),
                        _vm._v(
                          "занятия #" + _vm._s(_vm.lesson.id) + "\n        "
                        )
                      ])
                    : _vm.lesson.id
                    ? _c("div", [
                        _vm._v("\n          Запись о"),
                        _vm.lesson.timetable && _vm.lesson.timetable.online
                          ? _c("span", [_vm._v("б онлайн")])
                          : _vm._e(),
                        _vm._v(
                          " занятии #" + _vm._s(_vm.lesson.id) + "\n        "
                        )
                      ])
                    : _vm.lesson.canceled
                    ? _c("div", [
                        _vm._v("\n          Добавить запись об отмене "),
                        _vm.lesson.timetable && _vm.lesson.timetable.online
                          ? _c("span", [_vm._v("онлайн ")])
                          : _vm._e(),
                        _vm._v("занятия\n        ")
                      ])
                    : _c("div", [
                        _vm._v("\n          Добавить запись о"),
                        _vm.lesson.timetable && _vm.lesson.timetable.online
                          ? _c("span", [_vm._v("б онлайн")])
                          : _vm._e(),
                        _vm._v(" занятии\n        ")
                      ]),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.lessonDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1 text-center pt-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", md: "12" }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-0",
                              attrs: { "lazy-validation": "" }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Дата и время начала *",
                                          "error-messages": _vm.fieldError(
                                            "datetime"
                                          ),
                                          disabled: true
                                        },
                                        model: {
                                          value: _vm.lesson.datetime,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.lesson,
                                              "datetime",
                                              $$v
                                            )
                                          },
                                          expression: "lesson.datetime"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          required: "",
                                          label: "Занятие отменено",
                                          "false-value": 0,
                                          "true-value": 1,
                                          disabled:
                                            _vm.lesson.id &&
                                            _vm.lesson.clients &&
                                            _vm.lesson.clients.length > 0,
                                          "error-messages": _vm.fieldError(
                                            "canceled"
                                          )
                                        },
                                        model: {
                                          value: _vm.lesson.canceled,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.lesson,
                                              "canceled",
                                              $$v
                                            )
                                          },
                                          expression: "lesson.canceled"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          disabled: true,
                                          label: "Группа",
                                          items: _vm.timetables,
                                          "error-messages": _vm.fieldError(
                                            "timetable_id"
                                          ),
                                          "item-value": "id",
                                          "item-text": "name"
                                        },
                                        model: {
                                          value: _vm.lesson.timetable_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.lesson,
                                              "timetable_id",
                                              $$v
                                            )
                                          },
                                          expression: "lesson.timetable_id"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          disabled: true,
                                          label: "Расписание",
                                          items: _vm.schedules,
                                          "error-messages": _vm.fieldError(
                                            "timetable_schedule_id"
                                          ),
                                          "item-value": "id",
                                          "item-text": "name"
                                        },
                                        model: {
                                          value:
                                            _vm.lesson.timetable_schedule_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.lesson,
                                              "timetable_schedule_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "lesson.timetable_schedule_id"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Помещение *",
                                          items: _vm.rooms,
                                          "item-value": "id",
                                          "item-text": "name",
                                          "error-messages": _vm.fieldError(
                                            "room_id"
                                          )
                                        },
                                        model: {
                                          value: _vm.lesson.room_id,
                                          callback: function($$v) {
                                            _vm.$set(_vm.lesson, "room_id", $$v)
                                          },
                                          expression: "lesson.room_id"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          "error-messages": _vm.fieldError(
                                            "comment"
                                          ),
                                          rows: "1",
                                          "auto-grow": "",
                                          label: "Комментарий"
                                        },
                                        model: {
                                          value: _vm.lesson.comment,
                                          callback: function($$v) {
                                            _vm.$set(_vm.lesson, "comment", $$v)
                                          },
                                          expression: "lesson.comment"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "error-messages": _vm.fieldError(
                                            "teachers"
                                          ),
                                          color: "secondary",
                                          "item-color": "secondary",
                                          label: "Выбрать преподавателей",
                                          "item-value": "id",
                                          "item-text": "name",
                                          multiple: "",
                                          items: _vm.teachers
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item",
                                            fn: function(ref) {
                                              var attrs = ref.attrs
                                              var item = ref.item
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "mx-3 mb-2 v-sheet",
                                                        attrs: {
                                                          "active-class":
                                                            "secondary elevation-4 white--text",
                                                          elevation: "0"
                                                        }
                                                      },
                                                      "v-list-item",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            attrs: {
                                                              "item-value":
                                                                "item.id",
                                                              "return-object":
                                                                ""
                                                            },
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                item.name
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-scale-transition",
                                                      [
                                                        attrs.inputValue
                                                          ? _c(
                                                              "v-list-item-icon",
                                                              {
                                                                staticClass:
                                                                  "my-3"
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-check"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ]),
                                        model: {
                                          value: _vm.lesson.teachersIds,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.lesson,
                                              "teachersIds",
                                              $$v
                                            )
                                          },
                                          expression: "lesson.teachersIds"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.lesson.timetable &&
                              _vm.lesson.timetable.online
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", lg: "8" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "Ссылка-приглашение на конференцию",
                                              "error-messages": _vm.fieldError(
                                                "zoom_url"
                                              )
                                            },
                                            model: {
                                              value: _vm.lesson.zoom_url,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.lesson,
                                                  "zoom_url",
                                                  $$v
                                                )
                                              },
                                              expression: "lesson.zoom_url"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", lg: "4" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Пароль от конференции",
                                              "error-messages": _vm.fieldError(
                                                "zoom_password"
                                              )
                                            },
                                            model: {
                                              value: _vm.lesson.zoom_password,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.lesson,
                                                  "zoom_password",
                                                  $$v
                                                )
                                              },
                                              expression: "lesson.zoom_password"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c(
                                        "base-material-card",
                                        {
                                          staticClass: "px-5 py-3",
                                          attrs: {
                                            dark: "",
                                            color: "success",
                                            icon: "mdi-account-multiple-check",
                                            inline: "",
                                            title: "Присутствовали на занятии"
                                          }
                                        },
                                        [
                                          _c("v-data-table", {
                                            attrs: {
                                              headers: _vm.clientsHeaders,
                                              items: _vm.clients,
                                              "sort-by": ["name"],
                                              "disable-pagination": "",
                                              "hide-default-footer": "",
                                              "show-select": ""
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.name",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    item.name
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "client-pointer",
                                                            attrs: {
                                                              title:
                                                                "Открыть карточку клиента"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.singleClient(
                                                                  item.id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                " #" +
                                                                _vm._s(
                                                                  item.id
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      : item.parents
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "client-pointer",
                                                            attrs: {
                                                              title:
                                                                "Открыть карточку клиента"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.singleClient(
                                                                  item.id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  item.parents
                                                                ) +
                                                                " #" +
                                                                _vm._s(
                                                                  item.id
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "client-pointer",
                                                            attrs: {
                                                              title:
                                                                "Открыть карточку клиента"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.singleClient(
                                                                  item.id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          #" +
                                                                _vm._s(
                                                                  item.id
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.activeSubscriptions",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    !_vm.isCheckedClient(item)
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _vm.isMultiplyActiveSubscriptions(
                                                              item.activeSubscriptions
                                                            ) &&
                                                            !_vm.isOtherActiveSubscriptionInPriority(
                                                              item.activeSubscriptions,
                                                              _vm.subscription,
                                                              item
                                                            )
                                                              ? _c(
                                                                  "base-material-alert",
                                                                  {
                                                                    staticClass:
                                                                      "pt-1 pb-1 mt-1 mb-1",
                                                                    attrs: {
                                                                      color:
                                                                        "pink darken-1"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              mdi-alert-decagram-outline\n                            "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-light caption"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "На данное занятие доступны несколько абонементов.\n                              Занятие будет отмечено в первом по приоритету "
                                                                        ),
                                                                        _vm.isCurrentClient(
                                                                          item
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "текущем"
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " абонементе #" +
                                                                            _vm._s(
                                                                              item
                                                                                .activeSubscriptions[0]
                                                                                .id
                                                                            ) +
                                                                            ".\n                              Более приоритетным является абонемент у которого остались неиспользованные занятия и дата окончания наступает раньше."
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            !_vm.isMultiplyActiveSubscriptions(
                                                              item.activeSubscriptions
                                                            ) &&
                                                            _vm.isOtherActiveSubscriptionInPriority(
                                                              item.activeSubscriptions,
                                                              _vm.subscription,
                                                              item
                                                            )
                                                              ? _c(
                                                                  "base-material-alert",
                                                                  {
                                                                    staticClass:
                                                                      "pt-1 pb-1 mt-1 mb-1",
                                                                    attrs: {
                                                                      color:
                                                                        "pink darken-1"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              mdi-alert-decagram-outline\n                            "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-light caption"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Занятие будет отмечено в более приоритетном абонементе #" +
                                                                            _vm._s(
                                                                              item
                                                                                .activeSubscriptions[0]
                                                                                .id
                                                                            ) +
                                                                            "\n                              "
                                                                        ),
                                                                        _vm.isCurrentClient(
                                                                          item
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  ", вместо текущего абонемента #" +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .subscription
                                                                                        .id
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          ".\n                              Более приоритетным является абонемент у которого остались неиспользованные занятия и дата окончания наступает раньше."
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm.isMultiplyActiveSubscriptions(
                                                              item.activeSubscriptions
                                                            ) &&
                                                            _vm.isOtherActiveSubscriptionInPriority(
                                                              item.activeSubscriptions,
                                                              _vm.subscription,
                                                              item
                                                            )
                                                              ? _c(
                                                                  "base-material-alert",
                                                                  {
                                                                    staticClass:
                                                                      "pt-1 pb-1 mt-1 mb-1",
                                                                    attrs: {
                                                                      color:
                                                                        "pink darken-1"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              mdi-alert-decagram-outline\n                            "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-light caption"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "На данное занятие доступны несколько абонементов.\n                              Занятие будет отмечено в более приоритетном абонементе #" +
                                                                            _vm._s(
                                                                              item
                                                                                .activeSubscriptions[0]
                                                                                .id
                                                                            )
                                                                        ),
                                                                        _vm.isCurrentClient(
                                                                          item
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  ", вместо текущего абонемента #" +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .subscription
                                                                                        .id
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          ".\n                              Более приоритетным является абонемент у которого остались неиспользованные занятия и дата окончания наступает раньше."
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._l(
                                                      item.activeSubscriptions,
                                                      function(
                                                        activeSubscription,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key:
                                                              activeSubscription.id
                                                          },
                                                          [
                                                            _c(
                                                              "v-chip",
                                                              {
                                                                staticClass:
                                                                  "abo-chip",
                                                                class: {
                                                                  "abo-chip-first":
                                                                    index === 0,
                                                                  "abo-chip-other":
                                                                    index !== 0
                                                                },
                                                                attrs: {
                                                                  color:
                                                                    activeSubscription
                                                                      .extendedProps
                                                                      .colorClass,
                                                                  title: _vm.subscriptionTitle(
                                                                    activeSubscription.id
                                                                  )
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.singleSubscription(
                                                                      activeSubscription.id
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            [" +
                                                                    _vm._s(
                                                                      activeSubscription.start_date
                                                                    ) +
                                                                    ":" +
                                                                    _vm._s(
                                                                      activeSubscription.end_date
                                                                    ) +
                                                                    "] [#" +
                                                                    _vm._s(
                                                                      activeSubscription.id
                                                                    ) +
                                                                    "] " +
                                                                    _vm._s(
                                                                      activeSubscription.title
                                                                    ) +
                                                                    "\n                          "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "footer",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-card-text",
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              align: "center",
                                                              justify: "end"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "strong",
                                                              {
                                                                staticClass:
                                                                  "title"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Присутствовали"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "display-1 font-weight-light ml-12"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.lesson
                                                                        .clients
                                                                        .length
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ]),
                                            model: {
                                              value: _vm.lesson.clients,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.lesson,
                                                  "clients",
                                                  $$v
                                                )
                                              },
                                              expression: "lesson.clients"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      attrs: { rounded: "", "min-width": "100" },
                      on: {
                        click: function($event) {
                          _vm.lessonDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      attrs: {
                        rounded: "",
                        color: "secondary",
                        "min-width": "100",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.saveLesson()
                        }
                      }
                    },
                    [_vm._v("\n          Сохранить\n        ")]
                  ),
                  _vm.lesson.id
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: {
                            rounded: "",
                            color: "error",
                            "min-width": "100",
                            disabled: _vm.loading
                          },
                          on: {
                            click: function($event) {
                              _vm.confirmLessonDialog = true
                            }
                          }
                        },
                        [_vm._v("\n          Удалить\n        ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.confirmLessonDialog,
            callback: function($$v) {
              _vm.confirmLessonDialog = $$v
            },
            expression: "confirmLessonDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Удалить запись?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Запись будет удалена без возможности восстановления.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmLessonDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error darken-1",
                        text: "",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.removeLesson(_vm.lesson.id)
                        }
                      }
                    },
                    [_vm._v("\n          Удалить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.refundDialog,
            callback: function($$v) {
              _vm.refundDialog = $$v
            },
            expression: "refundDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v("\n        Возврат абонемента\n\n        "),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.refundDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1 text-center pt-0" },
                [
                  _c(
                    "v-row",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", md: "12" }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-5",
                              attrs: { "lazy-validation": "" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  "error-messages": _vm.fieldError("bill"),
                                  label: "Число занятий к возврату"
                                },
                                on: { keyup: _vm.refundCountChanged },
                                model: {
                                  value: _vm.refundCount,
                                  callback: function($$v) {
                                    _vm.refundCount = $$v
                                  },
                                  expression: "refundCount"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Сумма возрата средств клиенту",
                                  "error-messages": _vm.fieldError("payment"),
                                  messages:
                                    "Не может превышать сумму оплаты, внесенную за данный абонемент"
                                },
                                model: {
                                  value: _vm.refundMoneyValue,
                                  callback: function($$v) {
                                    _vm.refundMoneyValue = $$v
                                  },
                                  expression: "refundMoneyValue"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Примечание",
                                  "error-messages": _vm.fieldError("comment")
                                },
                                model: {
                                  value: _vm.refundComment,
                                  callback: function($$v) {
                                    _vm.refundComment = $$v
                                  },
                                  expression: "refundComment"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      attrs: {
                        color: "secondary",
                        rounded: "",
                        "min-width": "100",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.submitRefund()
                        }
                      }
                    },
                    [_vm._v("\n          Сделать возврат\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.dopSubscriptionDialog,
            callback: function($$v) {
              _vm.dopSubscriptionDialog = $$v
            },
            expression: "dopSubscriptionDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v(
                    "\n        Создать абонемент " +
                      _vm._s(_vm.subscription.client.name) +
                      "\n        "
                  ),
                  _c("div", { staticClass: "subtitle-2" }, [
                    _vm._v(
                      "\n          для компенсации пропущенных занятий\n        "
                    )
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.dopSubscriptionDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1 text-center pt-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", md: "12" }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-0",
                              attrs: { "lazy-validation": "" }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Число занятий",
                                          "error-messages": _vm.dopFieldError(
                                            "lessons_count"
                                          ),
                                          messages:
                                            "Кол-во зантий в исходном абонементе будет убавлено на данное кол-во"
                                        },
                                        model: {
                                          value:
                                            _vm.dopSubscription.lessons_count,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.dopSubscription,
                                              "lessons_count",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dopSubscription.lessons_count"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "startDateMenu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "return-value": _vm.dopStartDate,
                                            transition: "scale-transition",
                                            "min-width": "290px",
                                            "offset-y": ""
                                          },
                                          on: {
                                            "update:returnValue": function(
                                              $event
                                            ) {
                                              _vm.dopStartDate = $event
                                            },
                                            "update:return-value": function(
                                              $event
                                            ) {
                                              _vm.dopStartDate = $event
                                            }
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          readonly: "",
                                                          value: _vm.formatDate(
                                                            _vm.dopSubscription
                                                              .start_date
                                                          ),
                                                          label: "с *",
                                                          "error-messages": _vm.dopFieldError(
                                                            "start_date"
                                                          ),
                                                          color: "secondary",
                                                          "prepend-icon":
                                                            "mdi-calendar-outline",
                                                          messages:
                                                            "Дата начала действия абонемента"
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.dopStartDateMenu,
                                            callback: function($$v) {
                                              _vm.dopStartDateMenu = $$v
                                            },
                                            expression: "dopStartDateMenu"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                locale: "ru-ru",
                                                landscape: "",
                                                scrollable: "",
                                                "first-day-of-week": "1"
                                              },
                                              on: {
                                                input: function($event) {
                                                  _vm.dopStartDateMenu = false
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.dopSubscription
                                                    .start_date,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.dopSubscription,
                                                    "start_date",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dopSubscription.start_date"
                                              }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    large: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.dopStartDateMenu = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Cancel\n                      "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "endDateMenu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "return-value": _vm.dopEndDate,
                                            transition: "scale-transition",
                                            "min-width": "290px",
                                            "offset-y": ""
                                          },
                                          on: {
                                            "update:returnValue": function(
                                              $event
                                            ) {
                                              _vm.dopEndDate = $event
                                            },
                                            "update:return-value": function(
                                              $event
                                            ) {
                                              _vm.dopEndDate = $event
                                            }
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          readonly: "",
                                                          value: _vm.formatDate(
                                                            _vm.dopSubscription
                                                              .end_date
                                                          ),
                                                          label: "по *",
                                                          "error-messages": _vm.dopFieldError(
                                                            "end_date"
                                                          ),
                                                          color: "secondary",
                                                          "prepend-icon":
                                                            "mdi-calendar-outline",
                                                          messages:
                                                            "Дата окончания действия абонемента (включительно)"
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.dopEndDateMenu,
                                            callback: function($$v) {
                                              _vm.dopEndDateMenu = $$v
                                            },
                                            expression: "dopEndDateMenu"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                locale: "ru-ru",
                                                landscape: "",
                                                scrollable: "",
                                                "first-day-of-week": "1"
                                              },
                                              on: {
                                                input: function($event) {
                                                  _vm.dopEndDateMenu = false
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.dopSubscription.end_date,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.dopSubscription,
                                                    "end_date",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dopSubscription.end_date"
                                              }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    large: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.dopEndDateMenu = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Отмена\n                      "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          taggable: "",
                                          label: "Группа по расписанию",
                                          items: _vm.timetables,
                                          "error-messages": _vm.dopFieldError(
                                            "timetable_id"
                                          ),
                                          "item-value": "id",
                                          "item-text": "name",
                                          "no-data-text":
                                            "Укажите группы у клиента, чтобы добавить их к абонементу для корректного учета посещений занятий"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.onDopTimetableChange(
                                              $event
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.dopSubscription.timetable_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.dopSubscription,
                                              "timetable_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dopSubscription.timetable_id"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12", md: "12" }
                                    },
                                    [
                                      _c("v-textarea", {
                                        staticClass: "pt-0",
                                        attrs: {
                                          "error-messages": _vm.dopFieldError(
                                            "comment"
                                          ),
                                          rows: "1",
                                          "auto-grow": "",
                                          label: "Комментарий"
                                        },
                                        model: {
                                          value: _vm.dopSubscription.comment,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.dopSubscription,
                                              "comment",
                                              $$v
                                            )
                                          },
                                          expression: "dopSubscription.comment"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.dopLessonsByDates.length > 0
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _vm._v(
                                            "\n                  Занятий по расписанию в выбранный период: "
                                          ),
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dopLessonsByDates.length
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.dopLessonsByDates.length > 0
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        _vm._l(_vm.dopLessonsByDates, function(
                                          lesson
                                        ) {
                                          return _c(
                                            "v-chip",
                                            {
                                              key: lesson,
                                              attrs: { small: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(lesson) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      attrs: {
                        rounded: "",
                        color: "secondary",
                        "min-width": "100",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.saveDopSubscription()
                        }
                      }
                    },
                    [_vm._v("\n          Сохранить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.changePriceDialog,
            callback: function($$v) {
              _vm.changePriceDialog = $$v
            },
            expression: "changePriceDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v(
                    "\n        Изменение стоимости абонемента\n\n        "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.changePriceDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "base-material-alert",
                            {
                              attrs: {
                                dark: "",
                                color: "warning",
                                dismissible: ""
                              }
                            },
                            [
                              _c("span", { staticClass: "text-start body-2" }, [
                                _vm._v(
                                  "\n                Внимание! В зависимости от направления изменения стоимости будет создано либо списание, либо возврат списания,\n                что может привести к появлению задолженности или переплаты.\n                Производите данную опрерацию только в случае необходимости корректировки ошибок, и если вы понимаете что делаете.\n                В обычных случаях лучше создавать новый, отдельный абонемент или обратится за консультацией.\n              "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", md: "12" }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-5",
                              attrs: { "lazy-validation": "" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  label: "Текущая стоимость"
                                },
                                model: {
                                  value: _vm.changePriceOldValue,
                                  callback: function($$v) {
                                    _vm.changePriceOldValue = $$v
                                  },
                                  expression: "changePriceOldValue"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Новая стоимость",
                                  "error-messages": _vm.fieldError("newPrice")
                                },
                                model: {
                                  value: _vm.changePriceNewValue,
                                  callback: function($$v) {
                                    _vm.changePriceNewValue = $$v
                                  },
                                  expression: "changePriceNewValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      attrs: {
                        color: "secondary",
                        rounded: "",
                        "min-width": "100",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.submitChangePrice()
                        }
                      }
                    },
                    [_vm._v("\n          ИЗМЕНИТЬ\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300" },
          model: {
            value: _vm.anotherSubscriptionDialog,
            callback: function($$v) {
              _vm.anotherSubscriptionDialog = $$v
            },
            expression: "anotherSubscriptionDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _vm._v(
                  "\n        Это занятие было отмечено в другом абонементе #" +
                    _vm._s(_vm.anotherSubscriptionId) +
                    "."
                ),
                _c("br"),
                _c("br"),
                _vm._v("\n        Перейти к этому абонементу?\n      ")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.anotherSubscriptionDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.singleSubscription(
                            _vm.anotherSubscriptionId
                          )
                        }
                      }
                    },
                    [_vm._v("\n          Перейти\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }